@import "font.css"
@require "_inc/mixin.styl"

.mgt25{
    margin-top 25px !important
}
.mgt30{
    margin-top 30px !important
}
.mgt35{
    margin-top 35px !important
}
.mgt75{
    margin-top 75px !important
}

.tbl01{
    border-top 2px solid _color05
    border-bottom 1px solid _color05
    thead{
        background #e0f3dc
        th{
            background #e0f3dc
        }
	}
    tr{
        &.total{
            font-weight 700
            background #f1f1f1
        }
    }
    td{
        border-top _border1_line
        border-left _border1_line
        box-sizing border-box
        &:first-child{
            border-left none
        }
    }
}

.tbl02{
	border-bottom 1px solid _color05
	tr{
		&:first-child{
			border-top 2px solid _color05
		}
	}
	th,td{
		color #444
		font-size 16px
		border-top _border1_line
	}
	th{
		font-weight 700
		background #e0f3dc
	}
}

.tbl03{
    border-top 2px solid _color05
    border-bottom 1px solid _color05
    clearfix()
    li{
        position relative
        float left
        width 33.33%
        padding 40px 0
        color #444
        font-size 16px
        &:nth-child(2){
            background #ebf4e9
        }
    }
}

.tbl_lst{
    border-top 2px solid _color05
    border-bottom 1px solid _color05
    li{
        padding 20px 0
        border-top _border1_line
        clearfix()
        box-sizing border-box
    }
}

.main_con{
    width 100% !important
    padding 0 !important
}

.main_con01{
    position absolute
    top -90px
    left 50%
    width 1150px
    height 209px
    margin-left -575px
    padding 50px 100px 0
    background #FFF
    z-index 1
    box-sizing border-box
    box-shadow 0px 2px 40px -8px #000
    -moz-box-shadow 0 2px 40px -8px #000
    -webkit-box-shadow 0 2px 40px -8px #000
    &>div{
        display inline-block
        width 49%
        vertical-align middle
    }
    .left{
        position relative
        &:before{
            content ''
            position absolute
            top 6px
            left 0
            width 62px
            height 62px
            background url(/resources/images/main/ico_main01.png) no-repeat
        }
        p{
            padding-left 80px
            color #222
            font-size 26px
            font-weight 500
        }
    }
    .right{
        padding-left 30px
        border-left _border1_line
        box-sizing border-box
        strong{
            color _fontC4
            font-size 18px
            font-weight 700
        }
        p{
            margin-top 8px
            color _fontC3
            font-size 14px
            font-weight 200
            line-height 1.5
        }
    }
}

.main_con02{
    height 731px
    text-align center
    background url(/resources/images/main/bg_main01.jpg) 50% 50% no-repeat
    h2{
        padding-top 185px
        color #FFF
        font-size 35px
        font-weight 700
    }
    &>p{
        margin-top 10px
        color #FFF
        font-weight 200
    }
    ul{
        width 1150px
        margin 0 auto
        padding-top 40px
        clearfix()
        li{
            float left
            figure{
                img{
                    vertical-align bottom
                }
            }
            p{
                padding 20px 30px
                border-left 1px solid #1a3314
                box-sizing border-box
                color #FFF
                font-size 18px
                text-align left
                background rgba(29,60,23,0.8)
                &.first{
                    border-left none
                }
            }
        }
    }
}

.main_con03{
    height 530px
    text-align center
    background #FFF
    h2{
        padding-top 60px
        color #000
        font-size 35px
        font-weight 700
    }
    p{
        margin-top 10px
    }
    ul{
        width 1150px
        margin 0 auto
        padding-top 40px
        clearfix()
        li{
            float left
            width 270px
            margin-left 23px
            text-align left
            &:first-child{
                margin-left 0
            }
            a{
                &>span{
                    display block
                    margin-top 10px
                    color #27a81c
                    font-size 14px
                }
                strong{
                    display block
                    margin-top 5px
                    color #222
                    font-size 22px
                    font-weight 500
                }
                p{
                    color #666
                    font-size 14px
                    font-weight 200
                    span{
                        display block
                    }
                }
            }
        }
    }
}

.main_con04{
    height 398px
    padding-top 80px
    background #e8f0f0
    .inner{
        clearfix()
        width 1150px
        margin 0 auto
        .notice_area{
            float left
            position relative
            width 350px
            margin-top 35px
            h2{
                color #222
                font-size 22px
                font-weight 500
            }
            .main_notice{
                width 350px
                li{
                    margin-top 10px
                    a{
                        position relative
                        display block
                        padding 3px 100px 3px 0
                        color #666
                        font-size 14px
                        textellipsis()
                        em{
                            position absolute
                            right 0
                            font-weight 20
                        }
                    }
                }
            }
            .more{
                position absolute
                top 5px
                right 0
                texthidden()
                width 12px
                height 12px
                background url(/resources/images/main/btn_main_more.png) no-repeat
            }
        }
        &>ul{
            float right
            clearfix()
            li{
                float left
                width 354px
                height 236px
                color #FFF
                text-align center
                &:first-child{
                    background url(/resources/images/main/img_main10.jpg) no-repeat
                }
                &:nth-child(2){
                    background url(/resources/images/main/img_main11.jpg) no-repeat
                }
                a{
                    display block
                    h2{
                        padding-top 55px
                        font-size 18px
                        font-weight 700
                    }
                    .point{
                        margin-bottom 15px
                        span{
                            display block
                        }
                    }
                    span{
                        margin 0 auto
                        text-align center
                        font-size 12px
                        font-weight 200
                        &.label, &.arrow, &.item{
                            display inline-block
                        }
                        &.fluctuation{
                            display block
                        }
                    }
                    hr{
                        width 15px
                        margin 15px auto
                    }
                }
            }
        }
    }
}

.product{
    clearfix()
    li{
        float left
        margin-left 20px
        border _border1_line
        &:first-child{
            margin-left 0
        }
        p{
            height 49px
            color #FFF
            font-size 16px
            text-align center
            line-height @height
            background #6e7c94
        }
        img{
            display block
        }
        &:nth-child(4n+1){
            margin-left 0
        }
    }
}

.company{
    .img_box{
        margin 35px 0 60px 0
        text-align center
        img{
            margin-left -26px
            &:first-child{
                margin-left 0
            }
        }
    }
    table{
        width 100%
        th,td{
            height 60px
            vertical-align middle
        }
        th{
            text-align left
            padding 0 10px 0 30px
        }
        td{
            width 80%
            padding-left 30px
            &.bold{
                color _color05
                font-weight 700
            }
        }
    }
}

.greeting{
    div{
        clearfix()
        margin-top 40px
        figure{
            float right
        }
        .txt_box{
            padding-right 379px
            box-sizing border-box
            p{
                margin-top 30px
                padding-right 38px
                color #444
                font-size 16px
                &:first-child{
                    margin-top 0
                }
            }
        }
    }
}

.policies{
    margin 0 auto
    text-align center
    h4{
        margin-bottom 35px
        color #222
        font-size 24px
        font-weight 500
    }
    .management{
        margin-bottom 70px
        img{
            margin-left 37px
            &:first-child{
                margin-left 0
            }
        }
    }
    .tbl03{
        img{
            display block
            margin 0 auto
            padding-bottom 25px
        }
        span{
            position absolute
            top 30px
            left 30px
            color _color05
            font-size 16px
            font-weight 700
        }
    }
}

.history{
    position relative
    .dim{
        z-index 53
    }
    .btn_pop{
        position absolute
        top 170px
        right 0
        color #666
        font-size 13px
        vertical-align middle
        &:before{
            content ''
            display inline-block
            width 19px
            height 19px
            padding-right 3px
            background url(/resources/images/company/ico_history01.png) no-repeat
            vertical-align top
        }
    }
    .pop{
        display none
        position fixed
        top 50%
        left 50%
        margin-top -210px
        margin-left -375px
        width 750px
        background _bgC1
        z-index 53
        &>p{
            height 60px
            padding-left 30px
            color #FFF
            font-size 18px
            line-height @height
            background #222
            box-sizing border-box
        }
        div{
            padding 30px 30px 60px
            box-sizing border-box
            p{
                color #444
                font-size 16px
                line-height 1.6
            }
        }
        .btn_pop_close{
            position absolute
            top 17px
            right 25px
            texthidden()
            width 25px
            height 25px
            background url(/resources/images/company/btn_close_white.png) no-repeat
        }
    }
    .tabs{
        li{
            width 575px
            height 65px
            line-height @height
            color #444
            font-size 18px
            font-weight 500
        }
    }
    #tab1{
        background url(/resources/images/company/bg_history01.png) 50% 30px no-repeat
        ul{
            li{
                &:nth-of-type(odd){
                    margin-left 670px
                    &:first-child{
                        padding-top 22px
                    }
                }
                &:nth-of-type(even){
                    margin-right 670px
                    text-align right
                }
                &.2018{
                    
                }
            }
        }
    }
    #tab2{
        background url(/resources/images/company/bg_history02.png) 50% 30px no-repeat
        ul{
            li{
                &:nth-of-type(odd){
                    text-align right
                    margin-right 670px
                    &:first-child{
                        padding-top 22px
                    }
                }
                &:nth-of-type(even){
                    margin-left 670px
                }
            }
        }
    }
    #tab1, #tab2{
        ul{
            li{
                strong{
                    display block
                    margin-bottom 5px
                    color #444
                    font-size 20px
                    font-weight 700
                }
                p{
                    color #444
                    font-size 16px
                    line-height 1.6
                }
            }
        }
    }
}

.ethics{
    color #444
    font-size 16px
    h4{
        color _fontC4
        font-size 20px
        font-weight 500
    }
    p, ul, h5{
        padding-left 30px
    }
    h5{
        margin-top 15px
        margin-bottom 3px
        color #222
        font-size 16px
        font-weight 500
    }
    p{
        margin-top 20px
        margin-bottom 30px
        
    }
    ul{
        margin-bottom 30px
    }
}

.directions{
    color #444
    font-size 16px
    .tabs{
        margin-bottom 30px
        li{
            width 383px
            height 65px
            line-height @height
            color #444
            font-size 18px
            font-weight 500
            &:nth-child(2){
                width 384px
                border-left none
                border-right none
            }
        }
    }

    .tab_con{
        ul{
            li{
                position relative
                min-height 60px
                margin-top 35px
                padding 15px 0 0 70px
                box-sizing border-box
                &:before{
                    content ''
                    position absolute
                    top 0
                    left 0
                    width 60px
                    height 60px
                }
                h4{
                    margin-bottom 15px
                    font-size 18px
                    font-weight 500
                }
                &.car{
                    &:before{
                        background url(/resources/images/company/ico_directions01.png) no-repeat
                    }
                }
                &.bus{
                    &:before{
                        background url(/resources/images/company/ico_directions02.png) no-repeat
                    }
                }
                &.subway{
                    &:before{
                        background url(/resources/images/company/ico_directions03.png) no-repeat
                    }
                }
                strong{
                    display block
                    margin-bottom 3px
                    color #298718
                    font-size 18px
                    font-weight 500
                }
                p,span{
                    font-size 16px
                    font-weight 400
                    line-height 1.5
                }
                span{
                    display inline-block
                    position relative
                    vertical-align top
                    padding-left 45px
                    padding-right 70px
                    em{
                        position absolute
                        top 3px
                        left 0
                        padding 1px 5px
                        color #FFF
                        font-size 14px
                        &.green{
                            background #50a232
                        }
                        &.blue{
                            background #395cb4
                        }
                    }
                }
            }
        }
    }
    
    .map_area{
        img{
            display block
        }
        p{
            padding 25px 0
            border _border1_line
            text-align center
            span{
                &:first-child:after{
                    content ''
                    display inline-block
                    width 1px
                    height 14px
                    margin 0 20px
                    background #444
                    line-height 16px
                }
            }
        }
    }
}

.business_area{
    h4{
        margin 75px 0 25px
        color #222
        font-size 24px
        font-weight 500
        text-align center
    }
    .guidance_control{
        margin-top 40px
        li{
            clearfix()
            figure{
                float left
                width 575px
                height 350px
            }
            .txt_box{
                float right
                width calc(100% - 575px)
                padding 65px 0 0 50px
                font-size 16px
                box-sizing border-box
                em{
                    color #666
                    font-weight 200
                    vertical-align middle
                    &:before{
                        content ''
                        display inline-block
                        width 10px
                        height 4px
                        margin-right 5px
                        background #666
                        vertical-align middle
                    }
                }
                strong{
                    display block
                    margin-top 5px
                    padding-left 15px
                    color _fontC4
                    font-size 28px
                    font-weight 700
                }
                p{
                    margin-top 20px
                    padding-left 15px
                    color #444
                    line-height 1.5
                    span{
                        display block
                    }
                }
                .point{
                    color _fontC3
                }
            }
            &:nth-child(2){
                box-sizing border-box
                figure{
                    float right
                }
                .txt_box{
                    float left
                    padding 65px 50px 0 0
                    text-align right
                    p,strong{
                        padding-left 0
                        padding-right 15px
                    }
                    em{
                        &:before{
                            display none
                        }
                        &:after{
                            content ''
                            display inline-block
                            width 10px
                            height 4px
                            margin-left 5px
                            background #666
                            vertical-align middle
                        }
                    }
                }
            }
        }
    }
    table{
        width 100%
        th,td{
            padding 22px 10px 22px 30px
            text-align left
            box-sizing border-box
        }
        th{
            width 230px
        }
        span{
            display block
        }
    }
}

.customer{
    ul{
        padding 30px 0
        border-top 1px solid #8ab381
        box-sizing border-box
        clearfix()
        &.customer01{
            border-top none
        }
        li{
            float left
            padding-left 18px
            &:nth-child(5n+1){
                padding-left 0
            }
            img{
                width 215px
                height 130px
                border _border1_line
                box-sizing border-box
            }
            p{
                margin-bottom 30px
                text-align center
            }
        }
    }
}

.technique_area{
    .tabs{
        margin-top 40px
        li{
            width 191px
            height 65px
            font-size 18px
            font-weight 500
            line-height @height
            border-left none
            &:first-child, &:nth-child(6){
                width 193px
            }
            &:first-child{
                border-left _border1_line
            }
        }
    }
    .tab_con{
        h4{
            margin 40px 0 30px
            color #222
            font-size 24px
            font-weight 500
            text-align center
        }
        &>p{
            text-align center
            line-height 2
        }
        .keyword{
            margin-top 30px
            padding 25px 30px
            border _border1_line
            font-weight 500
            clearfix()
            li{
                line-height 1.7
                span{
                    display block
                    margin-left 12px
                }
            }
            &.fl{
                clearfix()
                li{
                    float left
                    width 50%
                }
            }
            
        }
        figure{
            margin 50px 0
            text-align center
        }
        &>table{
            width 100%
        }
        table{
            th,td{
                padding 22px 0
            }
            th{
                padding-left 30px
                text-align left
            }
            tbody{
                tr{
                    border-top none
                }
                td{
                    text-align center
                }
            }
        }
        .tbl_box{
            margin-top 30px
            clearfix()
            table{
                float left
                width 560px
                margin-left 30px
                &:first-child{
                    margin-left 0
                }
            }
        }
        &#tab2, &#tab3{
            .tbl_box{
                table{
                    width 375px
                    &:first-child{
                        width 745px
                    }
                }
            }
        }
        .product{
            li{
                margin-top 20px
            }
        }
        .tbl03{
            li{
                padding 25px 0 0 30px
                height 160px
                box-sizing border-box
                strong{
                    display block
                    margin-bottom 15px
                    color _fontC4
                    font-weight 700
                }
                p{
                    position relative
                    padding-left 7px
                    &:before{
                        content ''
                        display inline-block
                        position absolute
                        top 11px
                        left 0
                        width 4px
                        height 1px
                        margin-right 5px
                        background #444
                    }
                }
            }
        }
        .img_txt_lst{
            text-align center
            clearfix()
            li{
                float left
                width 33.33%
                p{
                    margin-top 10px
                    font-weight 500
                }
                span{
                    display block
                    margin-top 20px
                }
            }
        }
    }
    &.process, &.quality{
        .tabs>li{
            width 25%
        }
    }
    &.quality{
        #tab2{
            .img_txt_lst{
                padding 0 70px
                box-sizing border-box
                li{
                    width 50%
                }
            }
        }
    }
    &.certification{
        .tabs{
            li{
                width 383px
                &:nth-child(2){
                    width 384px
                }
            }
        }
        .tab_con{
            h4{
                margin 30px 0 20px
            }
            figure{
                margin 0
            }
        }
    }
}

.vision{
    text-align center
    &>p{
        margin-top 30px
    }
    ul{
        margin-top 35px
        clearfix()
        li{
            display inline-block
            margin-left -30px
            font-weight 500
            p{
                margin-top 10px
            }
            &:first-child{
                margin-left 0
                span{
                    color #045e9a
                }
            }
            &:nth-child(2){
                span{
                    color #197d07
                }
            }
            &:nth-child(3){
                span{
                    color #e38000
                }
            }
        }
    }
}

.personnel{
    margin 0 auto
    text-align center
    h4{
        margin-top 75px
        margin-bottom 30px
        color #222
        font-size 24px
        font-weight 500 
    }
    article{
        ul{
            margin-top 30px
            clearfix()
            li{
                position relative
                float left
                width 49%
                height 186px
                margin-left 2%
                padding 25px
                border _border1_line
                text-align left
                box-sizing border-box
                &:first-child{
                    margin-left 0
                    &:after{
                        content ''
                        position absolute
                        right 25px
                        bottom 25px
                        width 60px
                        height 60px
                        background url(/resources/images/recruitment/ico_personnel01.png) no-repeat
                    }
                }
                &:nth-child(2){
                    &:after{
                        content ''
                        position absolute
                        right 25px
                        bottom 25px
                        width 60px
                        height 60px
                        background url(/resources/images/recruitment/ico_personnel02.png) no-repeat
                    }
                }
                strong{
                    display block
                    margin-bottom 10px
                    font-weight 700
                }
            }
        }
        p{
            span{
                display block
            }
        }
    }
}

.welfare{
    ul{
        clearfix()
        li{
            position relative
            float left
            width calc(50% - 1px)
            height 300px
            margin 2px 0 0 2px
            overflow hidden
            &.last{
                width 100%
            }
            &:nth-of-type(odd){
                margin 2px 0 0 0
            }
            figure{
                position absolute
                top 0
                left 0
                z-index -1
            }
            span{
                display block
                color #FFF
                font-size 26px
                font-weight 500
                text-align center
                line-height 300px
            }
            .detail_pop{
                display table
                position absolute
                top 0
                left 0
                width 100%
                height 100%
                color #FFF
                font-weight 400
                text-align center
                vertical-align middle
                background #4ead38
                opacity 0.9
                z-index -3
                div{
                    display table-cell
                    vertical-align middle
                }
            }
            &:hover{
                &>.detail_pop{
                    z-index 1
                }
            }
        }
    }
}

.recruitment_guide{
    text-align center
    h4{
        margin 30px 0 20px
        color #222
        font-size 24px
        font-weight 500
    }
    &>p{
        margin-top 25px
    }
    p > span{
        display block
    }
    ul{
        margin-top 25px
        clearfix()
        li{
            position relative
            float left
            width 25%
            span{
                display block
                margin-top 10px
            }
            &:before{
                content ''
                position absolute
                top 58px
                left -36px
                width 69px
                height 45px
                background url(/resources/images/recruitment/ico_guide_arrow.png) no-repeat
            }
            &:first-child{
                &:before{
                    display none
                }
            }
        }
    }
    table{
        width 100%
        text-align left
        th,td{
            padding 22px 30px
            box-sizing border-box
        }
        th{
            width 230px
        }
        td{
            span{
                display block
            }
        }
    }
}

.stock{
    .tabs{
        margin-top 40px
        li{
            width 25%
            height 65px
            border-left none
            font-size 18px
            font-weight 500
            line-height @height
            &:first-child{
                border-left _border1_line
            }
        }
    }
    #tab3{
        thead{
            tr{
                &:first-child{
                    th:nth-child(2){
                        border-left _border1_line
                    }
                }
                &:nth-child(2){
                    th:nth-child(3){
                        border-left _border1_line
                    }
                }
            }
        }
        tr:first-child{
            border-bottom _border1_line
        }
    }
    #tab1, #tab3{
        table{
            tbody{
                text-align center
            }
        }
    }
    #tab2, #tab4{
        table{
            tbody{
                text-align right
                td{
                    clearfix()
                    &:first-child{
                        text-align center
                    }
                    span{
                        float left
                    }
                }
            }
        }
    }
    table{
        width 100%
        margin-top 30px
        thead{
            font-weight 700
        }
        th, td{
            padding 20px 30px
        }
    }
}

.notice_list{
    clearfix()
    .notice_tbl{
        width 100%
        margin-top 82px
        color #444
        font-size 16px
        clearfix()
        .top{
            background #e0f3dc
            clearfix()
            p{
                float left
                padding 20px 0
                text-align center
                color #444
                font-size 16px
                font-weight 700
                &:first-child{
                    width 88px
                }
                &:nth-child(2){
                    width calc(100% - 221px)
                }
                &:nth-child(3){
                    width 133px
                }
            }
        }
        li{
            p{
                float left
                color #444
                font-size 16px
                box-sizing border-box
                &:first-child{
                    width 88px
                    padding-left 10px
                    padding-right 10px
                    text-align center
                }
                &:nth-child(2){
                    width calc(100% - 221px)
                    padding-left 10px
                    padding-right 10px
                    textellipsis()
                }
                &:nth-child(3){
                    width 133px
                    color #666
                    font-size 14px
                    text-align center
                }
                a{
                    &:hover{
                        color _fontC3
                    }
                }
            }
            &.notice{
                p{
                    &:first-child{
                        font-size 0
                        &:before{
                            content '공지'
                            padding 3px 10px
                            color #FFF
                            font-size 16px
                            border-radius 8%
                            background _bgC2
                        }
                    }
                }
            }
        }
    }
}

.srch_area{
    float right
    margin 35px 0 10px 0
    clearfix()
    .select{
        float left
        width 98px
        clearfix()
    }
    .srch_box{
        position relative
        float left
        margin-left 10px
        input{
            width 250px
            height 37px
            padding 0 37px 0 10px
            color #444
            font-size 14px
        }
        a{
            position absolute
            width 37px
            height 37px
            top 0
            right 0
            text-indent -9999px
            background url(/resources/images/common/btn_srch.png) no-repeat center center
        }
    }
}

.notice_view{
    clearfix()
}

.btn_list{
    display block
    float right
    width 150px
    height 60px
    margin-top 30px
    color _fontC1
    font-size 18px
    line-height @height
    text-align center
    background _bgC2
}

#ViewArea{
    margin-top 40px
    clearfix()
    .view_tit{
        position relative
        width 100%
        padding 22px 161px 22px 30px
        border-top 2px solid _color05
        text-align left
        box-sizing border-box
        .tit{
            color #444
            font-size 18px
            font-weight 500
        }
        .date{
            position absolute
            top 24px
            right 30px
            color #666
            font-size 14px
        }
    }
    .view_box{
        width 100%
        padding 25px 10px 130px
        border-top _border1_line
        color #444
        font-size 16px
        text-align left
        box-sizing border-box
        img{
            display block
            margin 0 auto
            margin-bottom 25px
        }
    }
    .view_file{
        position relative
        padding 30px 10px
        color #666
        font-size 14px
        span{
            position absolute
            top 30px
            left 10px
        }
        .file_area{
            padding-left 66px
            box-sizing border-box
            a{
                display block
                vertical-align middle
                &:after{
                    content ''
                    display inline-block
                    width 18px
                    height 16px
                    margin-left 10px
                    vertical-align middle
                    background url(/resources/images/irpr/ico_down.png) no-repeat
                }
            }
        }
    }
    .post_navi{
        color #444
        font-size 16px
        .post_item{
            position relative
            padding 20px 10px 20px 115px
            border _border1_line
            background #f1f1f1
            box-sizing border-box
            &:first-child{
                border-bottom none
            }
            .item{
                position absolute
                top 20px
                left 10px
                vertical-align middle
            }
            &.next{
                .item{
                    &:before{
                        content ''
                        display inline-block
                        width 21px
                        height 21px
                        margin-right 10px
                        background url(/resources/images/irpr/ico_view_next.png)
                        vertical-align middle
                    }
                }  
            }
            &.prev{
                .item{
                    &:before{
                        content ''
                        display inline-block
                        width 21px
                        height 21px
                        margin-right 10px
                        background url(/resources/images/irpr/ico_view_prev.png)
                        vertical-align middle
                    }
                }
            }
            .post_tit{
                display block
                textellipsis()
            }
            a.post_tit{
                &:hover{
                    text-decoration underline
                }
            }
            span.post_tit{
                color #999
            }
        }
    }
}

.news_list{
    .news_tbl{
        width 100%
        margin-top 82px
        li{
            padding 0
            a{
                display block
                padding 20px 30px
                box-sizing border-box
                clearfix()
                &>p{
                    float left
                    width 280px
                    height 185px
                    border _border1_line
                    box-sizing border-box
                    img{
                        width 100%
                        height 100%
                    }
                }
                .txt_area{
                    float right
                    width calc(100% - 320px)
                    .tit{
                        margin-top 18px
                        color #444
                        font-size 18px
                        font-weight 500
                        textellipsis()
                    }
                    .txt{
                        height 3em
                        margin-top 15px
                        color #666
                        font-size 16px
                        line-height 1.5
                        -webkit-line-clamp 2
                        textellipsis_multi()
                    }
                    .date{
                        margin-top 40px
                        color #666
                        font-size 14px
                    }
                }
                &:hover{
                    .txt_area{
                        .tit{
                            color _fontC3
                        }
                        .txt{
                            text-decoration underline
                        }
                    }
                }
            }
        }
    }
}

.notice_view, .news_view{
    clearfix()
}

.ir{
    color #444
    font-size 16px
    text-align center
    clearfix()
    h4{
        float left
        margin-bottom 15px
        color #444
        font-size 24px
        font-weight 500
        text-align left
    }
    &>p{
        float right
    }
    .tbl_panel{
        margin-bottom 55px
    }
    table{
        width 100%
        .t_left{
            text-align left
        }
        .t_right{
            text-align right
        }
        thead{
            font-weight 700
        }
        th,td{
            padding 20px 30px
        }
        &.last_tbl{
            td{
                border-left _border1_line
                &.bln{
                    border-left none
                }
            }
        }
    }
}

@media screen and (max-width: 960px){

    .notice_list{
        .notice_tbl{
            width 100%
            margin-top 0
            border-top 1px solid _color05
            .top{
               display none
            }
            li{
                padding 20px 10px
                border-top _border1_line
                clearfix()
                p{
                    float none
                    &:first-child{
                        display inline-block
                        width auto
                        padding-left 0
                        padding-right 0
                        font-size 0
                        text-align left
                    }
                    &:nth-child(2){
                        display inline-block
                        width calc(100% - 70px)
                        padding 0
                    }
                    &:nth-child(3){
                        width auto
                        margin-top 15px
                        padding 0
                        text-align left
                    }
                }
                &.notice{
                    p{
                        &:first-child{
                            font-size 0
                            &:before{
                                margin-right 5px
                                border-radius 8%
                            }
                        }
                    }
                }
            }
        }
    }

    .srch_area{
        float none
        margin 15px 0 10px
        .select{
            width 20%
        }
        .srch_box{
            width calc(80% - 10px)
            input{
                width 100%
            }
        }
    }

    .btn_list{
        float none
        margin 0 auto
        margin-top 30px
        text-align center
    }

    #ViewArea{
        .view_tit{
            padding 22px 10px
            .date{
                position static
                margin-top 10px
            }
        }
    }

    .news_list{
        .news_tbl{
            margin-top 0
            li{
                a{
                    padding 20px 10px
                    &>p{
                        width 29vw
                        height 19vw
                    }
                    .txt_area{
                        position relative
                        width calc(100% - 33vw)
                        height 19vw
                        .tit{
                            height 2.6em
                            margin-top 10px
                            line-height 1.3
                            -webkit-line-clamp 2
                            white-space pre-wrap
                            textellipsis_multi()
                        }
                        .txt{
                            display none
                        }
                        .date{
                            position absolute
                            left 0
                            bottom 10px
                        }
                    }
                }
            }
        }
    }

    .ir{
        clearfix()
        .tbl_panel{
            width 100%
            overflow-y hidden
            overflow-x scroll
        }
        table{
            width 960px
            th,td{
                padding 13px 10px
            }
        }
    }

}

@media screen and (max-width: 560px){

    .notice_list{
        .notice_tbl{
            width 100%
            margin-top 0
            border-top 1px solid _color05
            .top{
               display none
            }
            li{
                padding 13px 10px
                border-top _border1_line
                clearfix()
                p{
                    float none
                    font-size 12px
                    &:first-child{
                        display inline-block
                        width auto
                        padding-left 0
                        padding-right 0
                        font-size 0
                        text-align left
                    }
                    &:nth-child(2){
                        display inline-block
                        width calc(100% - 60px)
                        padding 0
                    }
                    &:nth-child(3){
                        width auto
                        margin-top 10px
                        padding 0
                        font-size 10px
                        text-align left
                    }
                }
                &.notice{
                    p{
                        &:first-child{
                            font-size 0
                            &:before{
                                margin-right 5px
                                border-radius 8%
                                font-size 12px
                            }
                        }
                    }
                }
            }
        }
    }

    .srch_area{
        float none
        margin 15px 0 10px
        .select{
            width 20%
            select{
                font-size 11px
            }
        }
        .srch_box{
            width calc(80% - 10px)
            input{
                width 100%
            }
        }
    }

    .btn_list{
        width 145px
        height 40px
        margin-top 20px
        font-size 13px
        line-height @height
    }

    #ViewArea{
        margin-top 20px
        .view_tit{
            padding 15px 10px
            .tit{
                font-size 12px
            }
            .date{
                font-size 10px
            }
        }
        .view_box{
            padding 15px 10px
            font-size 12px
            img{
                margin-bottom 15px
            }
        }
        .view_file{
            padding 20px 10px
            font-size 10px
            span{
                top 20px
                left 10px
            }
            .file_area{
                padding-left 48px
            }
        }
        .post_navi{
            font-size 12px
            .post_item{
                position relative
                padding 13px 10px 13px 95px
                border _border1_line
                background #f1f1f1
                box-sizing border-box
                &:first-child{
                    border-bottom none
                }
                .item{
                    position absolute
                    top 12px
                    left 10px
                    vertical-align middle
                }
                &.next{
                    .item{
                        &:before{
                            content ''
                            display inline-block
                            width 21px
                            height 21px
                            margin-right 10px
                            background url(/resources/images/irpr/ico_view_next.png)
                            vertical-align middle
                        }
                    }  
                }
                &.prev{
                    .item{
                        &:before{
                            content ''
                            display inline-block
                            width 21px
                            height 21px
                            margin-right 10px
                            background url(/resources/images/irpr/ico_view_prev.png)
                            vertical-align middle
                        }
                    }
                }
                .post_tit{
                    display block
                    textellipsis()
                }
                a.post_tit{
                    &:hover{
                        text-decoration underline
                    }
                }
                span.post_tit{
                    color #999
                }
            }
        }
    }

    .news_list{
        .news_tbl{
            li{
                a{
                    padding 13px 10px
                    .txt_area{
                        .tit{
                            margin-top 5px
                            font-size 12px
                        }
                        .date{
                            bottom 5px
                            font-size 10px
                        }
                    }
                }
            }
        }
    }

    .ir{
        font-size 12px
        h4{
            margin-bottom 10px
            font-size 13px
        }
        &>p{
            float right
        }
    }

}